<template>
    <div class="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: scroll;">
      <div class="tab-content" style="width:100%;">
          <!-- 头部    -->
          <van-row justify="center" >
              <van-col  :span="24" >
                  <HeaderGame/>
              </van-col>
          </van-row>
          <van-row justify="center" >
              <van-col  :span="24">
                  <div  id="iframeGame">
                        <iframe class="gameframe" ref="gameframe" :src="detailUrl" frameborder="0" @load="onLoad"  />
                  </div>
                 
              </van-col>
          </van-row>
      </div>
  </div>        
  <!-- 底部    -->
  <van-row justify="center" >
      <van-col  :span="24" >
          <nav-bar />
      </van-col>
  </van-row>
</template>
<style>
    .gameframe {
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
    }
</style>
<script>
  import NavBar from '@/components/NavBar'
  import HeaderGame from '@/components/HeaderGame'
  import { reactive,toRefs } from "vue";  //nextTick
  export default{
      setup(){
          const state = reactive({
              detailUrl: 'https://zddnews.com/cs/zy/ac/detail/index.html'
          });

          return {
              ...toRefs(state)
          };
      },
      components: {
           NavBar,
           HeaderGame,
      }
  }
</script>